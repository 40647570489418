import { PageProps } from "gatsby"
import * as React from "react"
import useStoryblok from "../hooks/useStoryblok/useStoryblok.hook"
import DynamicTemplate from "../components/templates/DynamicTemplate/DynamicTemplate.component"
import StoryblokEntryTemplateContext from "../interfaces/Storyblok/StoryblokEntryTemplateContext.interface"

interface StoryBlokPreviewPageProps {}

const StoryBlokPreview: React.FC<PageProps<StoryBlokPreviewPageProps>> = ({
  pageContext,
  location,
}) => {
  const story = useStoryblok(null, location)

  if (!story) {
    return (
      <div className="py-8">
        <p className="italic text-center">Loading Preview...</p>
      </div>
    )
  }

  const content: any = story?.content

  const context: StoryblokEntryTemplateContext = {
    name: story?.name,
    id: story?.id,
    full_slug: story?.full_slug,
    field_component: content.component,
    content: story?.content,
    first_published_at: story?.first_published_at,
  }

  return <>{context && <DynamicTemplate context={context} />}</>
}

StoryBlokPreview.propTypes = {}

export default StoryBlokPreview
